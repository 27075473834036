<template>
  <div class="payments__page" v-if="!hideSetting">
    <Redirect class="" title="Settings" route="website.settings" />
    <v-row no-gutters v-if="!isLoading">
      <v-col class="page-header" cols="12">
        <h2 class="page-title">Payment providers</h2>
        <p class="">
          Enable payment providers to accept credit cards, Paypal, and other payment methods during checkout.Choose a
          payment provider to accept payment for orders.
        </p>
        <v-divider></v-divider>
      </v-col>
      <!-- Paypal -->
      <v-col v-if="pagePreference" class="px-3 py-3" cols="12" md="4">
        <h2><v-icon>fab fa-paypal</v-icon> Paypal</h2>
        <p class="mt-3">
          Accept PayPal Express Checkout as an additional payment method using a "Checkout with PayPal" button.
        </p>
        <!-- <v-btn
          class="mr-2"
          outlined
          normal
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="openChooseRotation('paypal-express')"
        >
          <v-icon class="pr-2">mdi-sync</v-icon>
          Payment rotation
        </v-btn> -->
        <v-btn
          class=""
          normal
          color="primary"
          v-if="
            rotations.find(item => item.paymentType === 'paypal-express') &&
              rotations.find(item => item.paymentType === 'paypal-express').activated &&
              paymentsPaypal.filter(item => item.active).length > 1
          "
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="deactive('paypal-express')"
        >
          Deactive
        </v-btn>
        <v-btn
          class=""
          normal
          color="primary"
          v-if="
            rotations.find(item => item.paymentType === 'paypal-express') &&
              !rotations.find(item => item.paymentType === 'paypal-express').activated &&
              paymentsPaypal.filter(item => item.active).length > 1
          "
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="active('paypal-express')"
        >
          Active
        </v-btn>
      </v-col>
      <!-- <v-col cols="12">
        <v-btn class="ma-2" outlined large fab color="indigo">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col> -->
      <v-col v-if="pagePreference" cols="12" md="8" class="px-3 py-3">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col cols="12">
              <Notification type="error" :messages="errors" v-if="errors.length > 0" />
              <div class="d-flex align-center">
                <div>
                  <h3>Paypal Credentials</h3>
                </div>
                <!-- <div class="ml-auto">
                  <v-btn><v-icon class="mr-3" color="#b5bdc2">mdi-cached</v-icon>Payment rotation</v-btn>
                </div> -->
              </div>
              <v-divider class="mt-5"></v-divider>
            </v-col>
            <v-col cols="12" v-if="isLoading">
              <SkeletonLoaders type="card-heading, list-item-three-line" />
            </v-col>
            <v-col v-else cols="12">
              <!-- <div>
                <div class="d-flex align-center">
                  <h3>Paypal Smart button</h3>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-icon-infor" dark v-bind="attrs" v-on="on">
                        <v-icon small>far fa-info-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      <v-img width="400" :src="require('@/assets/images/smart-button-tool-tip.webp')"></v-img>
                    </span>
                  </v-tooltip>
                  <v-switch v-model="payment.provider_options.enable_smart_button"></v-switch>
                </div>
                <small>
                  If any credit card option is activated, Paypal Smart Button is turned off in order to preserve your
                  store conversion rate.
                </small>
              </div> -->
              <!-- <div>
                <div class="d-flex align-center">
                  <h3 class="mr-5">Paypal order info autopilot</h3>
                  <v-switch v-model="payment.provider_options.disable_update_tracking"></v-switch>
                </div>
                <small>
                  Automatically update the order information, fulfillment and tracking information to your PayPal
                  account.Choose More settings for detail update configuration.
                </small>
              </div> -->

              <!-- <div class="mt-3">
                <div class="d-flex align-center">
                  <h3>Brand name</h3>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-icon-infor" dark v-bind="attrs" v-on="on">
                        <v-icon small>far fa-info-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      <v-img width="400" :src="require('@/assets/images/paypal-tooltip.webp')"></v-img>
                    </span>
                  </v-tooltip>
                </div>
                <div>
                  <v-text-field></v-text-field>
                </div>
              </div>

              <div class="">
                <v-btn color="primary">Save</v-btn>
              </div> -->

              <v-expansion-panels>
                <v-expansion-panel v-for="(payment, index) in paymentsPaypal" :key="index">
                  <v-expansion-panel-header disable-icon-rotate>
                    Account name:
                    <h4 class="ml-1">{{ payment.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="payment.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- <div>
                      <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
                    </div> -->
                    <div>
                      <span>* Client ID</span>
                      <v-text-field
                        v-model="payment.clientId"
                        :type="showClientKey ? 'text' : 'password'"
                        @click:append="showClientKey = !showClientKey"
                        :append-icon="showClientKey ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                    </div>
                    <div>
                      <span>* Client Secret Key</span>
                      <v-text-field
                        v-model="payment.clientSecret"
                        :type="showKey ? 'text' : 'password'"
                        @click:append="showKey = !showKey"
                        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                    </div>
                    <div class="d-flex">
                      <v-btn @click="onActive(payment)" color="primary">
                        {{ !payment.active ? 'Activate' : 'Deactivate' }}
                      </v-btn>
                      <!-- <v-btn class="mx-2" color="primary">Save</v-btn> -->
                      <v-btn @click="onRemove(payment.id)" class="ml-auto error">Remove account</v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12">
              <v-btn @click="showAdd">
                <v-icon depressed color="#b5bdc2" class="mr-2">mdi-plus</v-icon>Add another account
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Stripe -->
      <v-col v-if="pagePreference && !hideStripe" class="px-3 py-3" cols="12" md="4"
        ><h2><v-icon class="mr-2">fab fa-stripe-s</v-icon>Stripe</h2>
        <p class="mt-3">
          Accept Stripe Express Checkout as an additional payment method using a "Checkout with Stripe" button.
        </p>
        <v-btn
          class="mr-2"
          @click="openChooseRotation('stripe')"
          outlined
          normal
          :disabled="paymentsStripe.filter(item => item.active).length <= 1"
        >
          <v-icon class="pr-2">mdi-sync</v-icon>
          Payment rotation
        </v-btn>
        <v-btn
          class=""
          normal
          color="primary"
          v-if="
            rotations.find(item => item.paymentType === 'stripe') &&
              rotations.find(item => item.paymentType === 'stripe').activated &&
              paymentsStripe.filter(item => item.active).length > 1 &&
              !offRotationDeactive
          "
          :disabled="paymentsStripe.filter(item => item.active).length <= 1"
          @click="deactive('stripe')"
        >
          Deactive
        </v-btn>
      </v-col>
      <v-col v-if="pagePreference && !hideStripe" class="px-3 py-3" cols="12" md="8">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col sm="12"><h3>Stripe Credentials</h3></v-col>
            <v-divider></v-divider>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in paymentsStripe" :key="index">
                  <v-expansion-panel-header disable-icon-rotate
                    >Account name:
                    <h4 class="ml-1">{{ item.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="item.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="justify-space-between">
                      <v-col md="12">
                        <label for="">Public key</label>
                        <v-text-field v-model="item.clientId"></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <label for="">Secret key</label>
                        <v-text-field
                          v-model="item.clientSecret"
                          :type="showKey ? 'text' : 'password'"
                          @click:append="showKey = !showKey"
                          :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3"
                        ><v-btn @click="onActive(item)" color="primary" :disabled="onTestGetway">{{
                          !item.active == true ? 'Active' : 'Deactive'
                        }}</v-btn></v-col
                      >
                      <v-col md="4"><v-btn class="error" @click="onRemove(item.id)">Remove account</v-btn></v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn @click="onAddStriper">+ Add another account</v-btn></v-col>
          </v-row>
        </div>
      </v-col>

      <!-- PayDollar -->

      <v-col v-if="pagePreference && !hidePayDollar" class="px-3 py-3" cols="12" md="4">
        <h2><v-icon>fab fa-paypal</v-icon> PayDollar</h2>
        <!-- <p class="mt-3">
          Accept PayPal Express Checkout as an additional payment method using a "Checkout with PayPal" button.
        </p>
        <v-btn
          class="mr-2"
          outlined
          normal
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="openChooseRotation('paypal-express')"
        >
          <v-icon class="pr-2">mdi-sync</v-icon>
          Payment rotation
        </v-btn>
        <v-btn
          class=""
          normal
          color="primary"
          v-if="
            rotations.find(item => item.paymentType === 'paypal-express') &&
              rotations.find(item => item.paymentType === 'paypal-express').activated &&
              paymentsPaypal.filter(item => item.active).length > 1
          "
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="deactive('paypal-express')"
        >
          Deactive
        </v-btn>
        <v-btn
          class=""
          normal
          color="primary"
          v-if="
            rotations.find(item => item.paymentType === 'paypal-express') &&
              !rotations.find(item => item.paymentType === 'paypal-express').activated &&
              paymentsPaypal.filter(item => item.active).length > 1
          "
          :disabled="paymentsPaypal.filter(item => item.active).length <= 1"
          @click="active('paypal-express')"
        >
          Active
        </v-btn> -->
      </v-col>

      <v-col v-if="pagePreference && !hidePayDollar" cols="12" md="8" class="px-3 py-3">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col cols="12">
              <Notification type="error" :messages="errors" v-if="errors.length > 0" />
              <div class="d-flex align-center">
                <div>
                  <h3>PayDollar Credentials</h3>
                </div>
                <!-- <div class="ml-auto">
                  <v-btn><v-icon class="mr-3" color="#b5bdc2">mdi-cached</v-icon>Payment rotation</v-btn>
                </div> -->
              </div>
              <v-divider class="mt-5"></v-divider>
            </v-col>
            <v-col cols="12" v-if="isLoading">
              <SkeletonLoaders type="card-heading, list-item-three-line" />
            </v-col>
            <v-col v-else cols="12">
              <v-expansion-panels>
                <v-expansion-panel v-for="(payment, index) in paymentsPayDollar" :key="index">
                  <v-expansion-panel-header disable-icon-rotate>
                    Account name:
                    <h4 class="ml-1">{{ payment.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="payment.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
                    </div>
                    <div>
                      <span>* Merchant Id</span>
                      <v-text-field v-model="payment.clientId"></v-text-field>
                    </div>
                    <div>
                      <span>*Secure Hash Secret</span>
                      <v-text-field
                        v-model="payment.clientSecret"
                        :type="showKey ? 'text' : 'password'"
                        @click:append="showKey = !showKey"
                        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                    </div>
                    <div>
                      <span>* ApiAccount</span>
                      <v-text-field v-model="payment.apiAccount"></v-text-field>
                    </div>
                    <div>
                      <span>* Api Password</span>
                      <v-text-field v-model="payment.apiPassword"></v-text-field>
                    </div>
                    <div>
                      <span>Currency Code</span>
                      <v-select v-model="payment.currencyCode" :items="['USD', 'VND']"></v-select>
                    </div>
                    <div class="d-flex">
                      <v-btn @click="onActive(payment)" color="primary">
                        {{ !payment.active ? 'Activate' : 'Deactivate' }}
                      </v-btn>
                      <!-- <v-btn class="mx-2" color="primary">Save</v-btn> -->
                      <v-btn @click="onRemove(payment.id)" class="ml-auto error">Remove account</v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12">
              <v-btn @click="showAddPayDollar">
                <v-icon depressed color="#b5bdc2" class="mr-2">mdi-plus</v-icon>Add another account
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="pagePreference && !hideVtcPayment" class="px-3 py-3" cols="12" md="4">
        <h2><v-icon>fab fa-paypal</v-icon> VTCPayment</h2>
      </v-col>
      <v-col cols="12" md="8" class="px-3 py-3" v-if="pagePreference && !hideVtcPayment">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col cols="12">
              <Notification type="error" :messages="errors" v-if="errors.length > 0" />
              <div class="d-flex align-center">
                <div>
                  <h3>VTCPayment Credentials</h3>
                </div>
                <!-- <div class="ml-auto">
                  <v-btn><v-icon class="mr-3" color="#b5bdc2">mdi-cached</v-icon>Payment rotation</v-btn>
                </div> -->
              </div>
              <v-divider class="mt-5"></v-divider>
            </v-col>
            <v-col cols="12" v-if="isLoading">
              <SkeletonLoaders type="card-heading, list-item-three-line" />
            </v-col>
            <v-col v-else cols="12">
              <v-expansion-panels>
                <v-expansion-panel v-for="(payment, index) in paymentsVTCPay" :key="index">
                  <v-expansion-panel-header disable-icon-rotate>
                    Account name:
                    <h4 class="ml-1">{{ payment.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="payment.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
                    </div>

                    <div>
                      <span>* Wallet number</span>
                      <v-text-field v-model="payment.clientId"></v-text-field>
                    </div>
                    <div>
                      <span>* Website Secure Code</span>
                      <v-text-field
                        v-model="payment.clientSecret"
                        :type="showKey ? 'text' : 'password'"
                        @click:append="showKey = !showKey"
                        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                    </div>
                    <div>
                      <span>* Sign Key</span>
                      <v-text-field v-model="payment.signKey"></v-text-field>
                    </div>
                    <div>
                      <span>* Web ID</span>
                      <v-text-field v-model="payment.webId"></v-text-field>
                    </div>
                    <div>
                      <span>Currency Code</span>
                      <v-select v-model="payment.currencyCode" :items="['USD', 'VND']"></v-select>
                    </div>
                    <div class="d-flex">
                      <v-btn @click="onActive(payment)" color="primary">
                        {{ !payment.active ? 'Activate' : 'Deactivate' }}
                      </v-btn>
                      <!-- <v-btn class="mx-2" color="primary">Save</v-btn> -->
                      <v-btn @click="onRemove(payment.id)" class="ml-auto error">Remove account</v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12">
              <v-btn @click="showAddVTC">
                <v-icon depressed color="#b5bdc2" class="mr-2">mdi-plus</v-icon>Add another account
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <!-- NMI -->
      <v-col v-if="pagePreference && !hideDcomcy" class="px-3 py-3" cols="12" md="4">
        <h2><v-icon>fab fa-paypal</v-icon> Dcomcy Payment</h2>
      </v-col>
      <v-col v-if="pagePreference && !hideDcomcy" class="px-3 py-3" md="8">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col sm="12"><h3>Dcomcy Payment Credentials</h3></v-col>
            <v-divider></v-divider>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in paymentsNMI" :key="index">
                  <v-expansion-panel-header disable-icon-rotate
                    >Account name:
                    <h4 class="ml-1">{{ item.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="item.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="justify-space-between">
                      <v-col sm="12">
                        <div>
                          <v-switch v-model="item.testMode" label="Test mode"></v-switch>
                        </div>
                      </v-col>
                      <v-col md="12">
                        <label for="">Public key</label>
                        <v-text-field v-model="item.clientId"></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <label for="">Secret key</label>
                        <v-text-field
                          v-model="item.clientSecret"
                          :type="showKey ? 'text' : 'password'"
                          @click:append="showKey = !showKey"
                          :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3"
                        ><v-btn @click="onActive(item)" color="primary" :disabled="onTestGetway">{{
                          !item.active == true ? 'Active' : 'Deactive'
                        }}</v-btn></v-col
                      >
                      <v-col md="4"><v-btn class="error" @click="onRemove(item.id)">Remove account</v-btn></v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn @click="onAddNMI">+ Add another account</v-btn></v-col>
          </v-row>
        </div>
      </v-col>

      <!-- Accept credit cards -->
      <v-col cols="12" v-if="pagePreference && !hideCOD"
        ><CreditCard v-if="!isLoading" :testPayment="testPayment"
      /></v-col>
      <v-col v-if="pagePreference && !hideCOD" cols="12"
        ><COD v-if="paymentMethods && !isLoading" :paymentsCod="paymentsCod" :dataCod="dataCod"
      /></v-col>

      <!-- AIRWALLEX -->
      <v-col v-if="pagePreference && !hideAirwallex" class="px-3 py-3" cols="12" md="4">
        <h2>Airwallex</h2>
      </v-col>
      <v-col v-if="pagePreference && !hideAirwallex" class="px-3 py-3" md="8">
        <div class="elevation-form pa-3">
          <v-row>
            <v-col sm="12"><h3>Airwallex Credentials</h3></v-col>
            <v-divider></v-divider>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in paymentsAirwallex" :key="index">
                  <v-expansion-panel-header disable-icon-rotate
                    >Account name:
                    <h4 class="ml-1">{{ item.name }}</h4>
                    <template v-slot:actions>
                      <v-icon :color="item.active ? 'teal' : ''"> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="justify-space-between">
                      <v-col md="12">
                        <label for="">Public key</label>
                        <v-text-field v-model="item.clientId"></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <label for="">Secret key</label>
                        <v-text-field
                          v-model="item.clientSecret"
                          :type="showKey ? 'text' : 'password'"
                          @click:append="showKey = !showKey"
                          :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3"
                        ><v-btn @click="onActive(item)" color="primary" :disabled="onTestGetway">{{
                          !item.active == true ? 'Active' : 'Deactive'
                        }}</v-btn></v-col
                      >
                      <v-col md="4"><v-btn class="error" @click="onRemove(item.id)">Remove account</v-btn></v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn @click="onAddAirwallex">+ Add another account</v-btn></v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="4" v-if="pagePreference && !hidePayoneer" class="px-3 py-3">
        <h2>Payoneer</h2>
      </v-col>
      <v-col cols="12" md="8" v-if="pagePreference && !hidePayoneer" class="px-3 py-3">
        <div class="elevation-form pa-3">
          <h3>Payoneer Credentials</h3>
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, index) in paymentsPayoneer" :key="index">
              <v-expansion-panel-header disable-icon-rotate>
                Account name:
                <h4 class="ml-1">{{ item.name }}</h4>
                <template v-slot:actions>
                  <v-icon :color="item.active ? 'teal' : ''"> mdi-check </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row justify="space-between">
                  <v-col cols="12">
                    <label for="">API Username</label>
                    <v-text-field v-model="item.clientId" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="">Store Code</label>
                    <v-text-field v-model="item.webId" readonly></v-text-field>
                  </v-col>
                  <v-col md="3"
                    ><v-btn @click="onActive(item)" color="primary" :disabled="onTestGetway">{{
                      !item.active == true ? 'Active' : 'Deactive'
                    }}</v-btn></v-col
                  >
                  <v-col md="4"><v-btn class="error" @click="onRemove(item.id)">Remove account</v-btn></v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn class="mt-6" @click="onAddPayoneer">+ Add another account</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="pagePreference">
      <v-dialog v-model="dialog" persistent max-width="30vw">
        <v-card>
          <v-card-title class="d-flex align-center justify-space-between">
            <span class="text-h6 font-weight-bold">Payment rotation</span>
            <v-btn icon @click="cancel" class="font-weight-bold">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            You can add multiple payment accounts to your store receive payments. Dcomcy will split the revenue from
            orders evenly among the active accounts
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="rotationId">
                  <v-radio label="Auto rotation" value="auto"></v-radio>
                  <v-radio label="Purchase amount" value="money"></v-radio>
                  <v-text-field
                    v-if="rotationId === 'money'"
                    type="number"
                    class="col-6 ml-8"
                    v-model.number="maxPurchase"
                    :prefix="currency.currencyCode()"
                    min="0"
                    :rules="[rules.required, rules.int]"
                  ></v-text-field>
                  <v-radio label="Order amount" value="order"></v-radio>
                  <v-text-field
                    type="number"
                    v-if="rotationId === 'order'"
                    class="col-6 ml-8"
                    min="0"
                    :rules="[rules.required, rules.int]"
                    v-model.number="maxOrder"
                  ></v-text-field>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined normal @click="cancel">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <Modal model="showModal" title="Add another paypal account" :callback="submit">
      <template slot="content">
        <div>
          <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
        </div>
        <div>
          <span>* Title</span>
          <v-text-field v-model="payment.name"></v-text-field>
        </div>
        <div>
          <span>* Client ID</span>
          <v-text-field
            v-model="payment.clientId"
            :type="showClientKey ? 'text' : 'password'"
            @click:append="showClientKey = !showClientKey"
            :append-icon="showClientKey ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </div>
        <div>
          <span>* Client Secret Key</span>
          <v-text-field
            v-model="payment.clientSecret"
            :type="showKey ? 'text' : 'password'"
            @click:append="showKey = !showKey"
            :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </div>
      </template>
    </Modal>
    <Modal model="showModalPayDollar" title="Add PayDollar  account" :callback="submit">
      <template slot="content">
        <div>
          <span>* Name</span>
          <v-text-field v-model="payment.name"></v-text-field>
        </div>
        <div>
          <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
        </div>
        <div>
          <span>* Merchant Id</span>
          <v-text-field v-model="payment.clientId"></v-text-field>
        </div>
        <div>
          <span>* Secure Hash Secret</span>
          <v-text-field
            v-model="payment.clientSecret"
            :type="showKey ? 'text' : 'password'"
            @click:append="showKey = !showKey"
            :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </div>
        <div>
          <span>* Api Account</span>
          <v-text-field v-model="payment.apiAccount"></v-text-field>
        </div>
        <div>
          <span>* Api Password</span>
          <v-text-field v-model="payment.apiPassword"></v-text-field>
        </div>
        <div>
          <span>Currency Code</span>
          <v-select v-model="payment.currencyCode" :items="['USD', 'VND']"></v-select>
        </div>
      </template>
    </Modal>
    <Modal model="showModalVTC" title="Add VTC Payment  account" :callback="submit">
      <template slot="content">
        <div>
          <span>* Name</span>
          <v-text-field v-model="payment.name"></v-text-field>
        </div>
        <div>
          <v-switch v-model="payment.testMode" label="Test mode"></v-switch>
        </div>
        <div>
          <span>* Wallet number</span>
          <v-text-field v-model="payment.clientId"></v-text-field>
        </div>
        <div>
          <span>* Website Secure Code</span>
          <v-text-field
            v-model="payment.clientSecret"
            :type="showKey ? 'text' : 'password'"
            @click:append="showKey = !showKey"
            :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </div>
        <div>
          <span>* Sign Key</span>
          <v-text-field disabled v-model="payment.signKey"></v-text-field>
        </div>
        <div>
          <span>* Web ID</span>
          <v-text-field v-model="payment.webId"></v-text-field>
        </div>
        <div>
          <span>Currency Code</span>
          <v-select v-model="payment.currencyCode" :items="['USD', 'VND']"></v-select>
        </div>
      </template>
    </Modal>
    <modal model="onStripeDialog" title="Add another stripe account" :callback="submitStripe">
      <template slot="content">
        <v-row>
          <v-col sm="12">
            <label for="">Name</label>
            <v-text-field hide-details="" v-model="stripeDialog.name"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Public Key</label>
            <v-text-field hide-details="" v-model="stripeDialog.clientId"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Secret Key</label>
            <v-text-field hide-details="" v-model="stripeDialog.clientSecret"></v-text-field>
          </v-col>
        </v-row>
      </template>
    </modal>
    <modal model="onNMIDialog" title="Add another Dcomcy Payment account" :callback="submitNMI">
      <template slot="content">
        <v-row>
          <v-col sm="12">
            <div>
              <v-switch v-model="nmiDialog.testMode" label="Test mode"></v-switch>
            </div>
          </v-col>
          <v-col sm="12">
            <label for="">Name</label>
            <v-text-field hide-details="" v-model="nmiDialog.name"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Public key</label>
            <v-text-field hide-details="" v-model="nmiDialog.clientId"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Private Key</label>
            <v-text-field hide-details="" v-model="nmiDialog.clientSecret"></v-text-field>
          </v-col>
        </v-row>
      </template>
    </modal>
    <modal model="showAirWallexDialog" title="Add another airwallex account" :callback="submitAirwallex">
      <template slot="content">
        <v-row>
          <v-col sm="12">
            <div>
              <v-switch v-model="airwallexDialog.testMode" label="Test mode"></v-switch>
            </div>
          </v-col>
          <v-col sm="12">
            <label for="">Name</label>
            <v-text-field hide-details="" v-model="airwallexDialog.name" value="Airwallex"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Client Id</label>
            <v-text-field hide-details="" v-model="airwallexDialog.clientId"></v-text-field>
          </v-col>
          <v-col sm="12">
            <label for="">Api Key</label>
            <v-text-field hide-details="" v-model="airwallexDialog.clientSecret"></v-text-field>
          </v-col>
        </v-row>
      </template>
    </modal>
    <modal model="showPayoneerDialog" title="Add another Payoneer account" :callback="submitPayoneer">
      <template slot="content">
        <v-row>
          <v-col sm="12">
            <div>
              <v-switch v-model="payoneerDialog.testMode" label="Test mode"></v-switch>
            </div>
          </v-col>
          <v-col cols="12">
            <label>Name</label>
            <v-text-field hide-details="" v-model="payoneerDialog.name" value="Airwallex"></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>API Username</label>
            <v-text-field hide-details="" v-model="payoneerDialog.clientId"></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>API Token</label>
            <v-text-field hide-details="" v-model="payoneerDialog.clientSecret"></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Store Code</label>
            <v-text-field hide-details="" v-model="payoneerDialog.webId"></v-text-field>
          </v-col>
        </v-row>
      </template>
    </modal>
    <v-dialog v-model="alert" max-width="20vw" class="pa-0 ma-0">
      <v-card class="pa-0">
        <v-card-text class="pa-0">
          <v-alert type="error" :value="alert" color="red darken-1" class="ma-0 pa-6" dark>
            {{ message }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import COD from './COD';
import Redirect from '@/components/RedirectTo';
import Modal from '@/components/Modal';
import { paymentService } from '@/apis/payment';
import { log } from 'handlebars';
import CreditCard from './CreditCard.vue';
import event from '@/plugins/event-bus';
import currency from '@/helpers/currency';
import { mapState } from 'vuex';
import subscriptionService from '@/apis/subscription';

export default {
  components: {
    Redirect,
    Modal,
    CreditCard,
    COD,
  },
  data() {
    return {
      currency,
      condition: false,
      offRotationDeactive: false, // đ
      onTestGetway: null, // để để khi Active TestGateway thì Deactive hết các Stripe
      testPayment: {}, // lần đầu enable cái TestGateway thì trả về
      dataCod: {},
      showKey: false,
      showClientKey: false,
      showModal: false,
      showModalVTC: false,
      showModalPayDollar: false,
      isLoading: false,
      isLoadingAction: false,
      onNMIDialog: false,
      payment: {
        name: '',
        paymentType: 'paypal-express',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      },
      onStripeDialog: false,
      showAirWallexDialog: false,
      showPayoneerDialog: false,
      stripeData: [
        {
          name: '',
          paymentType: 'stripe',
          clientId: '',
          clientSecret: '',
          testMode: false,
          active: true,
        },
      ],
      stripeDialog: {
        name: '',
        paymentType: 'stripe',
        clientId: '',
        secretKey: '',
        testMode: false,
        active: true,
      },
      nmiDialog: {
        name: '',
        paymentType: 'nmi',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      },
      payoneerDialog: {
        name: '',
        paymentType: 'payoneer',
        clientId: '',
        clientSecret: '',
        webId: '',
        testMode: false,
        active: true,
      },
      paymentMethods: [],
      errors: [],
      dialog: false,
      rotationId: 'auto',
      type: '',
      maxOrder: '',
      maxPurchase: '',
      storeId: '',
      rotations: [],
      rules: {
        required: value => !!value || 'Required !',
        int: value => {
          const pattern = /^[0-9]+$/;
          return pattern.test(value) || 'you must enter an integer !';
        },
      },
      alert: false,
      message: 'test',
      airwallexDialog: {
        name: '',
        paymentType: 'airwallex',
        clientId: '',
        secretKey: '',
        testMode: false,
        active: true,
      },
      hideSetting: null,
      hideStripe: true,
      hidePayDollar: true,
      hideVtcPayment: true,
      hideDcomcy: true,
      hideAirwallex: true,
      hidePayoneer: true,
      hideCOD: true,
    };
  },
  watch: {
    paymentsStripe: function() {
      if (this.paymentsStripe.length > 1) {
        let a = this.paymentsStripe.filter(i => i.active == true);
        if (a.length < 2) {
          this.condition = true;
          // this.rotationId = 'auto';
          // this.maxPurchase = '';
          // this.maxOrder = '';
          this.offRotationDeactive = true;
          console.log(this.rotationId);
        }
      }
    },
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    paymentsPaypal() {
      return this.paymentMethods.filter(
        m => !m.paymentType || m.paymentType === '' || m.paymentType === 'paypal-express',
      );
    },
    paymentsStripe() {
      return this.paymentMethods.filter(m => m.paymentType === 'stripe' && m.isTestPayment == false);
    },
    paymentsNMI() {
      return this.paymentMethods.filter(m => m.paymentType === 'nmi' && m.isTestPayment == false);
    },
    paymentsPayDollar() {
      return this.paymentMethods.filter(m => m.paymentType === 'paydollar' && m.isTestPayment == false);
    },
    paymentsVTCPay() {
      return this.paymentMethods.filter(m => m.paymentType === 'vtcpay' && m.isTestPayment == false);
    },
    paymentsCod() {
      return this.paymentMethods.filter(m => m.paymentType === 'cod');
    },
    paymentsAirwallex() {
      return this.paymentMethods.filter(m => m.paymentType === 'airwallex' && m.isTestPayment == false);
    },
    // stripeActive() {
    //   return this.paymentMethods.filter(
    //     m => m.paymentType === 'stripe' && m.isTestPayment == false && m.active == true,
    //   );
    // },
    paymentsPayoneer() {
      return this.paymentMethods.filter(m => m.paymentType === 'payoneer' && m.isTestPayment == false);
    },
  },
  async created() {
    // window.location.reload();
    if (!this.$store.state.subscription.currentSubscription) await this.loadSubscription();
  },
  mounted() {
    const { currentSubscription, trialInfo } = this.$store.state.subscription;
    if (currentSubscription != null) {
      if (currentSubscription.code === 'LT_DROP_VIP') this.hideSetting = true;
    }
    this.loadPayments();
    this.loadRotation();
    if (localStorage.getItem('store-id')) {
      this.storeId = localStorage.getItem('store-id');
    }
  },
  methods: {
    onAddStriper() {
      this.onStripeDialog = true;
      this.stripeDialog = {
        name: '',
        paymentType: 'stripe',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      };
    },
    onAddAirwallex() {
      this.showAirWallexDialog = true;
      this.airwallexDialog = {
        name: '',
        paymentType: 'airwallex',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      };
    },
    onAddNMI() {
      this.onNMIDialog = true;
      this.nmiDialog = {
        name: '',
        paymentType: 'nmi',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      };
    },
    onAddPayoneer() {
      this.payoneerDialog = {
        name: '',
        paymentType: 'payoneer',
        clientId: '',
        clientSecret: '',
        webId: '',
        testMode: false,
        active: true,
      };
      this.showPayoneerDialog = true;
    },
    async activeStrip(item) {
      let data = {};
      data.name = item.name;
      data.publishKey = item.publishKey;
      data.secretKey = item.secretKey;
      data.secretKey = item.secretKey;
      try {
        if (item.active) {
          await paymentService.deactivate(item.id, data);
        } else {
          await paymentService.activate(item.id, data);
        }
      } catch (error) {
        this.error = error;
        this.loadPayments();
      }
    },
    async submitNMI() {
      this.isLoadingAction = true;
      try {
        let paymentData = await paymentService.create(this.nmiDialog);
        this.loadPayments();
        this.isLoadingAction = false;
        this.onNMIDialog = false;

        event.$emit('getStepStatus', message => {
          this.getStep();
        });
      } catch (error) {
        this.errors = ['The API credentials are invalid. Please check them again.'];
        this.isLoadingAction = false;
        this.onStripeDialog = false;
      }
    },
    async submitStripe() {
      this.isLoadingAction = true;
      try {
        let paymentData = await paymentService.create(this.stripeDialog);
        this.loadPayments();
        this.isLoadingAction = false;
        this.onStripeDialog = false;

        event.$emit('getStepStatus', message => {
          this.getStep();
        });
      } catch (error) {
        this.errors = ['The API credentials are invalid. Please check them again.'];
        this.isLoadingAction = false;
        this.onStripeDialog = false;
      }
    },
    async submitAirwallex() {
      this.isLoadingAction = true;
      try {
        await paymentService.create(this.airwallexDialog);
        this.loadPayments();
        this.isLoadingAction = false;
        this.showAirWallexDialog = false;
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
      } catch (error) {
        this.errors = ['The API credentials are invalid. Please check them again.'];
        this.isLoadingAction = false;
        this.onStripeDialog = false;
      }
    },
    async submitPayoneer() {
      try {
        this.isLoadingAction = true;
        await paymentService.create(this.payoneerDialog);
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
        this.loadPayments();
      } catch (error) {
        console.log('🚀 ~ file: Payments.vue:1165 ~ submitPayoneer ~ error:', error);
        this.errors = ['The API credentials are invalid. Please check them again.'];
      } finally {
        this.isLoadingAction = false;
        this.showPayoneerDialog = false;
      }
    },
    showAddVTC() {
      this.showModalVTC = true;
      this.payment = {
        name: '',
        paymentType: 'vtcpay',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
        signKey: ' 1!2@3#4$5%',
        currencyCode: 'VND',
        webId: '',
      };
    },
    showAddPayDollar() {
      this.showModalPayDollar = true;
      this.payment = {
        name: '',
        paymentType: 'paydollar',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
        apiAccount: '',
        currencyCode: 'VND',
        apiPassword: '',
      };
    },

    showAdd() {
      this.showModal = true;
      this.payment = {
        name: '',
        paymentType: 'paypal-express',
        clientId: '',
        clientSecret: '',
        testMode: false,
        active: true,
      };
    },
    async loadPayments() {
      this.isLoading = true;
      try {
        let paymentsData = await paymentService.list();
        this.paymentMethods = paymentsData.data;
        this.testPayment = Object.assign(
          {},
          this.paymentMethods.find(i => i.isTestPayment == true),
        );
        let cod = this.paymentMethods.find(i => i.paymentType == 'cod');
        // console.log(this.noteHtml);
        this.dataCod = Object.assign({}, cod);
        if (this.testPayment && this.testPayment.active) this.onTestGetway = true;
        else {
          this.onTestGetway = false;
        }
        this.isLoading = false;
      } catch (error) {
        this.errors = error;
        this.isLoading = false;
      }
    },
    async loadRotation() {
      let paymentsData = await paymentService.getRotation();
      this.rotations = paymentsData.data;
    },
    async submit() {
      this.isLoadingAction = true;
      try {
        let paymentData = await paymentService.create(this.payment);
        this.loadPayments();
        this.isLoadingAction = false;
        this.showModal = false;
        this.showModalVTC = false;
        this.showModalPayDollar = false;
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
      } catch (error) {
        this.errors = ['The API credentials are invalid. Please check them again.'];
        this.isLoadingAction = false;
        this.showModal = false;
      }
    },
    async onActive(payment) {
      this.isLoadingAction = true;
      let data = {};
      data.clientId = payment.clientId;
      data.testMode = payment.testMode;
      try {
        let paymentData = null;
        if (payment.active) {
          paymentData = await paymentService.deactivate(payment.id, data);
        } else {
          paymentData = await paymentService.activate(payment.id, data);
        }
        this.loadPayments();
      } catch (error) {
        this.errors = error;
      }
    },
    async onRemove(id) {
      this.isLoadingAction = true;
      try {
        let paymentData = await paymentService.deleteById(id);
        this.isLoadingAction = false;
        this.loadPayments();
      } catch (error) {
        this.isLoadingAction = false;
        this.errors = error;
        console.log(error);
      }
    },
    openChooseRotation(type) {
      this.type = type;
      let PaymentRotationDto;
      if (type === 'paypal-express') {
        PaymentRotationDto = this.rotations.find(item => item.paymentType === 'paypal-express');
      } else if (type === 'stripe') {
        PaymentRotationDto = this.rotations.find(item => item.paymentType === 'stripe');
      }
      console.log(PaymentRotationDto);
      if (PaymentRotationDto && !this.condition) {
        // console.log(PaymentRotationDto);
        // this.rotationId = 'auto';
        this.rotationId = PaymentRotationDto.rotationType;
        if (this.rotationId === 'money') {
          this.maxPurchase = PaymentRotationDto.rotationValue;
        } else if (this.rotationId === 'order') {
          this.maxOrder = PaymentRotationDto.rotationValue;
        } else if (this.rotationId === 'auto') {
          this.maxPurchase = '';
          this.maxOrder = '';
        }
      } else {
        this.rotationId = 'auto';
        this.maxPurchase = '';
        this.maxOrder = '';
      }
      this.dialog = true;
    },
    cancel() {
      this.type = '';
      this.rotationId = 'auto';
      this.maxPurchase = '';
      this.maxOrder = '';
      this.dialog = false;
    },
    async save() {
      let PaymentRotationDto = {
        store: this.storeId,
        paymentType: this.type,
        rotationType: this.rotationId,
        activated: true,
      };
      switch (this.rotationId) {
        case 'auto': {
          PaymentRotationDto.rotationValue = 1;
          break;
        }
        case 'money': {
          PaymentRotationDto.rotationValue = this.maxPurchase;
          break;
        }
        case 'order': {
          PaymentRotationDto.rotationValue = this.maxOrder;
          break;
        }
      }
      if (Number.isInteger(PaymentRotationDto.rotationValue)) {
        try {
          await paymentService.createRotation(PaymentRotationDto);
          this.loadRotation();
        } catch (e) {
          console.log(e);
        }
        this.dialog = false;
      } else {
        if (this.rotationId === 'money') {
          this.message = 'Purchase amount must a integer !';
        } else if (this.rotationId === 'order') {
          this.message = 'Order amount must a integer !';
        }
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
          this.message = '';
        }, 1000);
      }
      this.offRotationDeactive = false;
      this.condition = false;
    },
    async deactive(type) {
      let PaymentRotationDto;
      if (type === 'paypal-express') {
        PaymentRotationDto = Object.assign(
          {},
          this.rotations.find(item => item.paymentType === 'paypal-express'),
          { activated: false },
        );
      } else if (type === 'stripe') {
        PaymentRotationDto = Object.assign(
          {},
          this.rotations.find(item => item.paymentType === 'stripe'),
          { activated: false },
        );
        this.rotationId = 'auto';
        this.maxPurchase = '';
        this.maxOrder = '';
      }
      if (PaymentRotationDto) {
        try {
          await paymentService.activeRotation(PaymentRotationDto);
          this.loadRotation();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async active(type) {
      let PaymentRotationDto;
      if (type === 'paypal-express') {
        PaymentRotationDto = Object.assign(
          {},
          this.rotations.find(item => item.paymentType === 'paypal-express'),
          { activated: true },
        );
      } else if (type === 'stripe') {
        PaymentRotationDto = Object.assign(
          {},
          this.rotations.find(item => item.paymentType === 'stripe'),
          { activated: true },
        );
      }
      if (PaymentRotationDto) {
        try {
          await paymentService.activeRotation(PaymentRotationDto);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async loadSubscription() {
      let d = await subscriptionService.currentSubscription();
      let subscription = d.data?.data?.origin;
      if (subscription != null) {
        if (subscription.code === 'LT_DROP_VIP') this.hideSetting = true;
      }
    },
  },
};
</script>
<style lang="scss">
.payments__page {
  .v-tooltip__content {
    z-index: 10;
    opacity: 1 !important;
  }
  .btn-icon-infor {
    background-color: transparent !important;
    color: #333 !important;
    box-shadow: none;
    font-size: 13px;
    min-width: 20px !important;
  }
  .btn-icon-infor:hover {
    background-color: #fff !important;
  }
  .btn-icon-infor:before {
    background-color: #fff !important;
  }
}
.v-tooltip__content {
  z-index: 10;
  opacity: 1 !important;
}
</style>
